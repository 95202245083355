.App, body, * {
  /*text-align: center;*/
  font-family: 'Didact Gothic', 'DM Sans', sans-serif !important;
}

body{  
  background-color: #faf7f1;
}
body {
  overflow-x: hidden;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* will write a css code for popup */
.thumbnail {
  width: 100px; /* Adjust the width and height as per your requirements */
  height: 100px;
  border-radius: 50%; /* Make the image circular */
  object-fit: cover; /* Ensure the image fills the thumbnail without distortion */
}

h1{
  font-weight: 900 !important;
}

.placeholder {
  opacity: 1;
  transition: opacity 0.3s;
}

.placeholder.hide {
  opacity: 0;
}

.iframe {
  opacity: 0;
  transition: opacity 0.3s;
}

.iframe.show {
  opacity: 1;
}

.iframe-container {
  width: 100%;
  height: 500px; /* Adjust height as needed */
  overflow: hidden;
}

.iframe-container iframe{  
  width: 100%;
  height: 100%;
}

.iframe-container iframe::-webkit-scrollbar {
  width: 0.1em;
}

.iframe-container iframe::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.iframe-container iframe::-webkit-scrollbar-thumb {
  background-color: #888;
}
/*
img{
   opacity: 0.7
}
*/
label{
  display: inherit;
}

.chat-window-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.chat-window-content {
  flex: 1;
  overflow-y: auto;
  padding: 16px; /* Adjust this value based on the height of your AppBar */
  margin-bottom: 80px;
}

.input-container {
  display: flex;
  align-items: center;
  padding: 8px;
  position: fixed;
  bottom: 0;
  left: 0;
  /*width: 100%;*/
  background-color: #f5f5f5;
}

.send-icon {
  margin-left: 8px;
  cursor: pointer;
}

hr{
  border: 2px solid transparent;
  -webkit-border-image: url(https://www.w3schools.com/css/border.png) 20% round;
  -o-border-image: url(https://www.w3schools.com/css/border.png) 20% round;
  border-image: url(https://www.w3schools.com/css/border.png) 20% round;
  text-align: center;
}

.left-sidebar-editor select, .left-sidebar-editor select:focus-visible, .left-sidebar-editor select:focus-within{
  background: none;
  border: none;
}

.pointer{
  cursor: pointer;
}

.text-light-comfort{
  color: #dbd8d8 !important;
}

.bg-light-comfort{
  background-color: #ededed !important;
}
.bg-light-comfort-deeper{
  background-color: #dbd8d8 !important;
}
.bg-light-comfort-cover{
  background-color: #ededed !important;
  z-index: 50000;
}

.tool-palette {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
}
.sketch-picker{
  position: relative;
  z-index: 100000;
}

/* Add more styles as needed */
